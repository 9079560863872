import React from 'react';

import { useApolloClient } from '@apollo/client';

import { api, useMe } from 'api';

import { NavigationPage } from 'layouts/LayoutApp/Navigation';
import { useUserOnline } from 'utils/apollo/subscriptions';

import { Layout } from '../Layout';
// import { NavbarActivePage } from '../Navbar/Navbar';

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  title?: string;

  onLogoClick?: () => void;
  onClickGame?: (game: api.GameId, event: React.MouseEvent) => void;
  onTabClick?: (tab: NavigationPage, event: React.MouseEvent) => void;
}

/**
 *  @deprecated
 * Used only on old pages (no user)
 */
export const AppLayout: React.FC<LayoutProps> = ({ children, title, ...props }) => {
  const me = useMe();
  const client = useApolloClient();

  useUserOnline(client, []);

  return (
    <Layout.App>
      <Layout.Left me={me} {...props} />
      <Layout.Main title={title}>{children}</Layout.Main>
    </Layout.App>
  );
};
