import { useEffect, useRef } from 'react';

import { ApolloClient } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { api, getToken } from 'api';

const host = process.env.NEXT_PUBLIC_API;
const protocol = process.env.NEXT_PUBLIC_PROTOCOL === 'HTTP' ? 'ws' : 'wss';

export const subscriptionUrl = `${protocol}://${host}/subscriptions`;

export function useGroupUpdates<T = unknown>(
  eventId: string,
  client: ApolloClient<T>,
  deps: any[] = [],
): void {
  const token = getToken();

  return useEffect(() => {
    if (!token) {
      return;
    }

    const subscriptionClient = new SubscriptionClient(subscriptionUrl + '?group', {
      reconnect: true,
    });

    const apolloClient = new ApolloClient({
      link: new WebSocketLink(subscriptionClient),
      cache: client.cache,
    });

    apolloClient
      .subscribe<api.EventUpdateSubscription, api.EventUpdateSubscriptionVariables>({
        query: api.EventUpdateDocument,
        variables: { eventId: eventId, options: { token } },
      })
      .subscribe({});

    return () => subscriptionClient.close();
  }, [...deps, token]);
}

interface GroupNano {
  id: string;
}
export function useGroupListUpdates<T = unknown>(
  groups: api.Maybe<GroupNano[]>,
  client: ApolloClient<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[] = [],
): void {
  const token = getToken();

  const ids =
    groups?.map(group => group.id).filter(id => !id.startsWith('merchant-')) || [];

  return useEffect(() => {
    if (!token) {
      return;
    }
    // console.log('subscribing to groups:', JSON.stringify(ids));
    if (ids.length === 0) {
      return;
    }

    // console.log('Listening to groups:', ids);
    const subscriptionClient = new SubscriptionClient(
      subscriptionUrl + '?group-list-updates',
      { reconnect: true },
    );

    const apolloClient = new ApolloClient({
      link: new WebSocketLink(subscriptionClient),
      cache: client.cache,
    });

    const sub = apolloClient
      .subscribe<
        api.EventUpdateListSubscription,
        api.EventUpdateListSubscriptionVariables
      >({
        query: api.EventUpdateListDocument,
        variables: { eventIds: ids, options: { token } },
      })
      .subscribe({});

    return () => {
      sub.unsubscribe();
      subscriptionClient.close();
    };
  }, [JSON.stringify(ids), ...deps, token]);
}

let clientInitialized = false;

export function useUserOnline<T = unknown>(
  client: ApolloClient<T> | null,
  deps: any[] = [],
): void {
  const initialized = useRef<boolean>(false);
  const token = getToken();

  return useEffect(() => {
    if (!client) {
      return;
    }

    if (!token || token === '') {
      return;
    }
    if (initialized.current) {
      return;
    }

    if (typeof window !== 'undefined') {
      if (clientInitialized) {
        return;
      }
    }

    const subscriptionClient = new SubscriptionClient(subscriptionUrl + '?online', {
      reconnect: true,
    });

    const apolloClient = new ApolloClient({
      link: new WebSocketLink(subscriptionClient),
      cache: client.cache,
    });

    apolloClient
      .subscribe<api.UserOnlineSubscription, api.UserOnlineSubscriptionVariables>({
        query: api.UserOnlineDocument,
        variables: { options: { token } },
      })
      .subscribe({});

    if (typeof window !== 'undefined') {
      clientInitialized = true;
    }

    // return () => {
    //   sub.unsubscribe();
    //   subscriptionClient.close();
    // };
  }, [...deps, client, token]);
}
