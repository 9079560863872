import React, { memo, useEffect } from 'react';

import cx from 'classnames';
import { useRouter } from 'next/router';

import { analytics } from 'analytics';
import { api, useMe } from 'api';
import { AvatarUser } from 'components/Avatar';
import { Button } from 'components/Button';
import { useApp } from 'components/ContextApp';
import { useEventModal } from 'components/ContextModal/event';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Icon } from 'components/Icons';
import { LoaderBouncing } from 'components/Loaders';
import { Text } from 'components/Text';
import { HeaderSearch } from 'layouts/LayoutApp/HeaderSearch';
import { Navigation, NavigationPage } from 'layouts/LayoutApp/Navigation';
import { useText } from 'text';
import { useScrollRestoration } from 'utils/main';
import { routePlayersCoder } from 'utils/url/pagePlayers';

// import { Footer } from '../Footer';
// import { Navbar } from '../Navbar';
// import { NavbarActivePage, NavbarButtonCreateGroup } from '../Navbar/Navbar';
// import { ModalAuthOnboarding } from '../../containers/ModalAuthOnboarding';
// import { SlideoverSettings } from '../SlideoverSettings';

import css from './Layout.module.css';

interface LayoutProps {
  children?: React.ReactNode;
  // for storybook
  style?: React.CSSProperties;
  className?: string;
  childrenClassName?: string;
  title?: string;
  isHeader?: boolean;
  withOffset?: boolean;
  backgroundImage?: string;
  canEdit?: boolean;
  onRequestSearch?: (
    prefix: string,
    event: React.FormEvent,
  ) => Promise<string | void> | string | void;
  onGoBackComplete?: () => void;
  onRequestUploadCover?: () => void;
}

interface AsideProps extends LayoutProps {
  me?: api.Maybe<api.User>;
  onLogoClick?: () => Promise<void> | void;
  onClickGame?: (game: api.GameId, event: React.MouseEvent) => void;
  onTabClick?: (tab: NavigationPage, event: React.MouseEvent) => void;
}

export const App = memo<LayoutProps>(({ children, style }) => {
  const router = useRouter();
  useScrollRestoration(router);

  return (
    <main className={css.appWrapper}>
      {/* <ModalAuthOnboarding onAuthSuccess={onAuthSuccess} /> */}
      <div className={css.app} style={style}>
        {children}
      </div>
    </main>
  );
});

export const Header = memo<LayoutProps>(({ children, style }) => {
  return (
    <div className={css.headerWrapper}>
      <header className={css.header} style={style}>
        {children}
      </header>
    </div>
  );
});

export const StickyHeader = memo<LayoutProps>(
  ({
    children,
    style,
    withOffset,
    backgroundImage,
    className,
    childrenClassName,
    canEdit = false,
    onRequestUploadCover = () => undefined,
  }) => {
    const text = useText(state => state.controls);

    const imageStyle: React.CSSProperties = {
      background: `linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.0086472) 10%, rgba(22, 22, 22, 0.03551) 13.33%, rgba(22, 22, 22, 0.0816599) 20%, rgba(22, 22, 22, 0.147411) 26.67%, rgba(22, 22, 22, 0.231775) 33.33%, rgba(22, 22, 22, 0.331884) 40%, rgba(22, 22, 22, 0.442691) 46.67%, rgba(22, 22, 22, 0.557309) 53.33%, rgba(22, 22, 22, 0.668116) 60%, rgba(22, 22, 22, 0.768225) 66.67%, rgba(22, 22, 22, 0.852589) 73.33%, rgba(22, 22, 22, 0.91834) 80%, rgba(22, 22, 22, 0.96449) 86.67%, rgba(22, 22, 22, 0.991353) 93.33%, #161616 100%), url(${backgroundImage})`,
    };

    return (
      <header
        className={cx(css.stickyHeader, className)}
        style={{
          ...style,
        }}
      >
        <div
          className={css.headerImageWrapper}
          onClick={() => {
            if (!canEdit) {
              return;
            }

            onRequestUploadCover();
          }}
        >
          {canEdit && !backgroundImage ? (
            <div className={css.editHeader}>
              <Icon.Gallery />
              <Text.R3Memo semibold Tag="span">
                {text.actions.clickToUploadCover}
              </Text.R3Memo>
            </div>
          ) : null}
          <div
            className={cx(css.headerImage, { [css.editableHeaderImage]: canEdit })}
            style={withOffset ? imageStyle : {}}
          />
        </div>
        <div className={cx(css.stickyHeaderChildren, childrenClassName)}>
          {children}
        </div>
        <div className={css.separatorHeader} />
      </header>
    );
  },
);

export const Left: React.FC<AsideProps> = ({
  me,
  style,
  children,
  onLogoClick,
  onClickGame = () => undefined,
  onTabClick = () => undefined,
}) => {
  const router = useRouter();
  const app = useApp();

  return (
    <aside className={css.leftWrapper} style={style}>
      <Navigation
        isAuthed={Boolean(me)}
        language={app.language}
        myGames={me?.lfg?.map(lfg => lfg.gameId) || []}
        pathname={router.pathname}
        query={router.query}
        onGameClick={(gameId, event, isMyGame) => {
          if (isMyGame) {
            analytics.myGameClick(gameId);
          } else {
            analytics.lfgGameClick(gameId);
          }

          onClickGame(gameId, event);
        }}
        onItemClick={onTabClick}
        onLogoClick={onLogoClick}
        onRequestChangeLanguage={lang => app.setLanguage(lang)}
      />
      <div className={css.left}>{children}</div>
    </aside>
  );
};
interface AuthButtonProps {
  me: api.Maybe<api.User>;
  onClick?: () => void;
}

const AuthButton: React.FC<AuthButtonProps> = ({ me, onClick = () => undefined }) => {
  const text = useText(state => state.controls.loginModal);

  if (me) {
    return null;
  }

  return (
    <Button className={css.authButton} onClick={onClick}>{`${text.action}`}</Button>
  );
};

export const Content: React.FC<
  LayoutProps & { routeName: string; extended?: boolean }
> = ({
  children,
  style,
  title,
  routeName,
  isHeader = true,
  extended = false,
  onRequestSearch,
}) => {
  return (
    <section
      className={cx(css.content, { [css.contentExtended]: extended })}
      id="layout_contect"
      style={style}
    >
      <ContentHeder
        isHeader={isHeader}
        routeName={routeName}
        title={title}
        onRequestSearch={onRequestSearch}
      />
      {children}
    </section>
  );
};

interface ContentWithTheme {
  backgroundImage: string;
  children?: any;
  className?: string;
}

export const ContentWithTheme: React.FC<ContentWithTheme> = ({
  children,
  className,
}) => {
  return (
    <div className={cx(css.themeHeaderWrapper, className)}>
      <div className={css.scrollContentWrapper}>{children}</div>
    </div>
  );
};

const RightSectionHeader = () => {
  const authModalText = useText(state => state.controls.loginModal);
  const authOnboardingText = useText(
    state => state.modalAuthOnboarding.loginScenario,
  );

  const authModalTitle = authOnboardingText.desc;

  const onboardingModal = useOnboardingModal();
  const eventModal = useEventModal();

  const me = useMe();

  const renderProfileButtons = () => {
    if (!me) {
      return null;
    }

    return (
      <>
        <Button
          fullWidth
          style={{ flexGrow: 1 }}
          onClick={() => {
            analytics.clickCreateGroup('header');

            if (!me) {
              return onboardingModal.open({
                title: authModalText.createGroupTitle,
                onSuccess: () => eventModal.open({}),
              });
            }

            eventModal.open({});
          }}
        >
          {authModalText.gameSettings.createGroup}
        </Button>
        {/* <Link href={'/notifications'}>
          <a>
            <div className={css.notificationIconWrapper}>
              <Icon.Notification className={css.notificationIcon} />
            </div>
          </a>
        </Link> */}

        <AvatarUser
          className={css.userAvatar}
          user={me}
          onLinkClick={() => analytics.clickProfile()}
        />
      </>
    );
  };

  return (
    <header className={cx(css.layoutHeader, css.rightSectionHeader)}>
      <div className={cx(css.headerButtons, { [css.profileButtons]: me })}>
        <AuthButton
          me={me}
          onClick={() => {
            analytics.clickLogin();
            onboardingModal.open({ title: authModalTitle });
          }}
        />
        {/* <NavbarButtonCreateGroup
          onClick={() => {
            events.clickCreateGroup('header');

            if (!me) {
              return onboardingModal.open({
                title: authModalText.createGroupTitle,
                onSuccess: () => newGroup.open({}),
              });
            }

            newGroup.open({});
          }}
        /> */}
        {renderProfileButtons()}
      </div>
    </header>
  );
};

export const Right = memo<AsideProps>(({ children, style }) => {
  return (
    <aside className={cx(css.rightWrapper)} style={style}>
      <RightSectionHeader />
      <div className={css.right}>{children}</div>
    </aside>
  );
});

export const Center = memo<LayoutProps>(({ children, style }) => {
  return (
    <div className={css.loading} style={style}>
      {children}
    </div>
  );
});

export const Main = memo<LayoutProps>(({ children }) => {
  return <main className={css.main}>{children}</main>;
});

export const ContentHeder = memo<LayoutProps & { routeName: string }>(
  ({ title, isHeader = true, onRequestSearch }) => {
    const router = useRouter();

    return (
      <>
        {isHeader ? (
          <header className={cx(css.layoutHeader, css.contentHeader)}>
            <Text.H1 className={css.headerTitle}>{title}</Text.H1>

            <div className={css.rightSection}>
              <HeaderSearch
                defaultValue={router.query.user_name as string}
                onRequestSearch={async (name, event) => {
                  if (onRequestSearch) {
                    await onRequestSearch(name, event);

                    return;
                  }

                  const query = routePlayersCoder.encode({
                    filter: { user: { username: name } },
                    iteratorOptions: { limit: 10 },
                  });

                  router.push({
                    pathname: '/players',
                    query: query.toString(),
                  });

                  return;
                }}
              />
            </div>
          </header>
        ) : null}
      </>
    );
  },
);

export const Wrapper = memo<LayoutProps>(({ children, style }) => {
  const onboardingModal = useOnboardingModal();

  useEffect(() => {
    (window as any).onboardingModal = onboardingModal;
    // (window as any).openAddGame = addGame.open;
  }, [onboardingModal]);

  return (
    <div className={css.wrapper} style={style}>
      <main className={css.mainWrapper}>{children}</main>
    </div>
  );
});
export const Loading = memo(() => {
  return (
    <Center>
      <LoaderBouncing />
    </Center>
  );
});
